// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadInput_uploadInput__FKswy {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.UploadInput_uploadInput__FKswy + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: #0c1327;
    display: inline-block;
    cursor: pointer;
    border: 2px solid #263c7c;
    padding: 1rem;
    border-radius: 99rem;
    transition: background-color 0.2s, border-color 0.2s;
}

.UploadInput_uploadInput__FKswy:focus + label,
.UploadInput_uploadInput__FKswy + label:hover {
    background-color: #263c7c;
    border: 2px solid #4066d1;
}

.UploadInput_uploadInput__FKswy:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/UploadInput/UploadInput.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,qBAAqB;IACrB,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,oBAAoB;IACpB,oDAAoD;AACxD;;AAEA;;IAEI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;IACxB,0CAA0C;AAC9C","sourcesContent":[".uploadInput {\n    width: 0.1px;\n    height: 0.1px;\n    opacity: 0;\n    overflow: hidden;\n    position: absolute;\n    z-index: -1;\n}\n\n.uploadInput + label {\n    font-size: 1.25em;\n    font-weight: 700;\n    color: white;\n    background-color: #0c1327;\n    display: inline-block;\n    cursor: pointer;\n    border: 2px solid #263c7c;\n    padding: 1rem;\n    border-radius: 99rem;\n    transition: background-color 0.2s, border-color 0.2s;\n}\n\n.uploadInput:focus + label,\n.uploadInput + label:hover {\n    background-color: #263c7c;\n    border: 2px solid #4066d1;\n}\n\n.uploadInput:focus + label {\n    outline: 1px dotted #000;\n    outline: -webkit-focus-ring-color auto 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadInput": `UploadInput_uploadInput__FKswy`
};
export default ___CSS_LOADER_EXPORT___;
