// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/soccer-ball.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Loader_box__wORI1 {
    margin: 0 auto;
    width: 40px;
    height: 140px;
    position: relative;
}

.Loader_shadow__jvuvI {
    position: absolute;
    width: 100%;
    height: 10px;
    background-color: grey;
    bottom: 0;
    border-radius: 100%;
    transform: scaleX(.8);
    opacity: .6;
    animation: Loader_shadowScale__JJZpo 1s linear infinite;
}

.Loader_gravity__52jso {
    width: 40px;
    height: 40px;
    animation: Loader_bounce__SB0bB 1s cubic-bezier(0.68, 0.35, 0.29, 0.54) infinite;
}

.Loader_ball__bC8Ar {
    width: 40px;
    height: 40px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    animation: Loader_roll__WnBIg .7s linear infinite;
}

@keyframes Loader_roll__WnBIg {
    0% {
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes Loader_bounce__SB0bB {
    0% {
    }
    50% {
        transform: translateY(100px)
    }
    100% {
    }
}

@keyframes Loader_shadowScale__JJZpo {
    0% {
    }
    50% {
        transform: scaleX(1);
        opacity: .8;
    }
    100% {
    }
}

.Loader_loader__\\+lRPl {
    font-size: 5vh;
    color: white;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;IACX,uDAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gFAAkE;AACtE;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yDAAwD;IACxD,sBAAsB;IACtB,iDAAmC;AACvC;;AAEA;IACI;IACA;IACA;QACI;IACJ;AACJ;;AAEA;IACI;IACA;IACA;QACI;IACJ;IACA;IACA;AACJ;;AAEA;IACI;IACA;IACA;QACI,oBAAoB;QACpB,WAAW;IACf;IACA;IACA;AACJ;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["\n.box {\n    margin: 0 auto;\n    width: 40px;\n    height: 140px;\n    position: relative;\n}\n\n.shadow {\n    position: absolute;\n    width: 100%;\n    height: 10px;\n    background-color: grey;\n    bottom: 0;\n    border-radius: 100%;\n    transform: scaleX(.8);\n    opacity: .6;\n    animation: shadowScale 1s linear infinite;\n}\n\n.gravity {\n    width: 40px;\n    height: 40px;\n    animation: bounce 1s cubic-bezier(0.68, 0.35, 0.29, 0.54) infinite;\n}\n\n.ball {\n    width: 40px;\n    height: 40px;\n    background-image: url('../../../public/soccer-ball.png');\n    background-size: cover;\n    animation: roll .7s linear infinite;\n}\n\n@keyframes roll {\n    0% {\n    }\n    100% {\n        transform: rotate(360deg)\n    }\n}\n\n@keyframes bounce {\n    0% {\n    }\n    50% {\n        transform: translateY(100px)\n    }\n    100% {\n    }\n}\n\n@keyframes shadowScale {\n    0% {\n    }\n    50% {\n        transform: scaleX(1);\n        opacity: .8;\n    }\n    100% {\n    }\n}\n\n.loader {\n    font-size: 5vh;\n    color: white;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `Loader_box__wORI1`,
	"shadow": `Loader_shadow__jvuvI`,
	"shadowScale": `Loader_shadowScale__JJZpo`,
	"gravity": `Loader_gravity__52jso`,
	"bounce": `Loader_bounce__SB0bB`,
	"ball": `Loader_ball__bC8Ar`,
	"roll": `Loader_roll__WnBIg`,
	"loader": `Loader_loader__+lRPl`
};
export default ___CSS_LOADER_EXPORT___;
