// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shimmer-button_shimmer_button__MSWoj {
    animation: Shimmer-button_shimmer__yGc1G 2s linear infinite;
}

@keyframes Shimmer-button_shimmer__yGc1G {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -200% 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Shimmer-button/Shimmer-button.module.css"],"names":[],"mappings":"AAAA;IACI,2DAAqC;AACzC;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".shimmer_button {\n    animation: shimmer 2s linear infinite;\n}\n\n@keyframes shimmer {\n    from {\n        background-position: 0 0;\n    }\n    to {\n        background-position: -200% 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shimmer_button": `Shimmer-button_shimmer_button__MSWoj`,
	"shimmer": `Shimmer-button_shimmer__yGc1G`
};
export default ___CSS_LOADER_EXPORT___;
