// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SparklesComponent_wrapper__8wY1d {
    position: relative;
    display: inline-block;
    width: -moz-fit-content;
    width: fit-content;
}

.SparklesComponent_child_wrapper__LqB3y {
    position: relative;
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/SparklesComponent/SparklesComponent.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".wrapper {\n    position: relative;\n    display: inline-block;\n    width: fit-content;\n}\n\n.child_wrapper {\n    position: relative;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `SparklesComponent_wrapper__8wY1d`,
	"child_wrapper": `SparklesComponent_child_wrapper__LqB3y`
};
export default ___CSS_LOADER_EXPORT___;
