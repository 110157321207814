// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EyesLoader_eyesLoader__NgI3L {
    display: inline-flex;
    gap: 10px;
}

.EyesLoader_eyesLoader__NgI3L:before,
.EyesLoader_eyesLoader__NgI3L:after {
    content: "";
    height: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: linear-gradient(#020617, #020617) top/100% 0 no-repeat,
    radial-gradient(farthest-side, #000 95%, transparent) 50%/8px 8px no-repeat #fff;
    animation: EyesLoader_l9__hi3j7 4s infinite ease-in;
}

@keyframes EyesLoader_l9__hi3j7 {
    0% {
        background-size: 100% 0, 8px 8px;
        background-position: top, 50% 50%
    }
    80% {
        background-size: 100% 70%, 8px 8px;
        background-position: top, 50% 70%
    }
    84%,
    100% {
        background-size: 100% 0, 8px 8px;
        background-position: top, 50% 50%
    }
}`, "",{"version":3,"sources":["webpack://./src/components/EyesLoader/EyesLoader.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,SAAS;AACb;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB;oFACgF;IAChF,mDAAiC;AACrC;;AAEA;IACI;QACI,gCAAgC;QAChC;IACJ;IACA;QACI,kCAAkC;QAClC;IACJ;IACA;;QAEI,gCAAgC;QAChC;IACJ;AACJ","sourcesContent":[".eyesLoader {\n    display: inline-flex;\n    gap: 10px;\n}\n\n.eyesLoader:before,\n.eyesLoader:after {\n    content: \"\";\n    height: 20px;\n    aspect-ratio: 1;\n    border-radius: 50%;\n    background: linear-gradient(#020617, #020617) top/100% 0 no-repeat,\n    radial-gradient(farthest-side, #000 95%, transparent) 50%/8px 8px no-repeat #fff;\n    animation: l9 4s infinite ease-in;\n}\n\n@keyframes l9 {\n    0% {\n        background-size: 100% 0, 8px 8px;\n        background-position: top, 50% 50%\n    }\n    80% {\n        background-size: 100% 70%, 8px 8px;\n        background-position: top, 50% 70%\n    }\n    84%,\n    100% {\n        background-size: 100% 0, 8px 8px;\n        background-position: top, 50% 50%\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eyesLoader": `EyesLoader_eyesLoader__NgI3L`,
	"l9": `EyesLoader_l9__hi3j7`
};
export default ___CSS_LOADER_EXPORT___;
