// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUp_signUp_container__nrIaK {
    background: #020617;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;
    gap: 5vh;
}

.SignUp_title_container__F2Dof {
    display: flex;
}

.SignUp_signUp_title__21tjr, .SignUp_signUp_title_slim__5l1oM {
    font-size: 8rem;
    color: white;
    font-weight: bold;
}

.SignUp_signUp_title_slim__5l1oM {
    font-weight: lighter;
}

.SignUp_signUp_form__f-oA- {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    z-index: 10;
}

.SignUp_signUp_input__19mzr {
    min-width: 25vw;
}

.SignUp_show_password__6ibMZ {
    background: transparent;
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.SignUp_show_password__6ibMZ svg {
    margin-right: 10px;
    fill: #1ca0fb;
    cursor: pointer;
}

.SignUp_relative_container__nq-AM {
    position: relative;
}

@media (max-width: 768px) {
    .SignUp_signUp_container__nrIaK {
        padding-top: 15vh;
    }

    .SignUp_title_container__F2Dof > h1 {
        text-align: center;
        font-size: 4rem;
    }

    .SignUp_signUp_form__f-oA- {
        width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/SignUp/SignUp.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,QAAQ;AACZ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,QAAQ;IACR,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,kBAAkB;QAClB,eAAe;IACnB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".signUp_container {\n    background: #020617;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 5vh;\n    gap: 5vh;\n}\n\n.title_container {\n    display: flex;\n}\n\n.signUp_title, .signUp_title_slim {\n    font-size: 8rem;\n    color: white;\n    font-weight: bold;\n}\n\n.signUp_title_slim {\n    font-weight: lighter;\n}\n\n.signUp_form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 1rem;\n    z-index: 10;\n}\n\n.signUp_input {\n    min-width: 25vw;\n}\n\n.show_password {\n    background: transparent;\n    border: none;\n    position: absolute;\n    right: 0;\n    top: 50%;\n    transform: translateY(-50%);\n}\n\n.show_password svg {\n    margin-right: 10px;\n    fill: #1ca0fb;\n    cursor: pointer;\n}\n\n.relative_container {\n    position: relative;\n}\n\n@media (max-width: 768px) {\n    .signUp_container {\n        padding-top: 15vh;\n    }\n\n    .title_container > h1 {\n        text-align: center;\n        font-size: 4rem;\n    }\n\n    .signUp_form {\n        width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signUp_container": `SignUp_signUp_container__nrIaK`,
	"title_container": `SignUp_title_container__F2Dof`,
	"signUp_title": `SignUp_signUp_title__21tjr`,
	"signUp_title_slim": `SignUp_signUp_title_slim__5l1oM`,
	"signUp_form": `SignUp_signUp_form__f-oA-`,
	"signUp_input": `SignUp_signUp_input__19mzr`,
	"show_password": `SignUp_show_password__6ibMZ`,
	"relative_container": `SignUp_relative_container__nq-AM`
};
export default ___CSS_LOADER_EXPORT___;
