// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SparkleInstance_sparkle__5a9KY {
  animation: SparkleInstance_spin__bGKsh 600ms linear forwards;
}

.SparkleInstance_sparkle_wrapper__DO2Ur {
  position: absolute;
  pointer-events: none;
  z-index: 2;
  animation: SparkleInstance_growAndShrink__HnKYQ 600ms ease-in-out forwards;
}

@keyframes SparkleInstance_spin__bGKsh {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

@keyframes SparkleInstance_growAndShrink__HnKYQ {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Sparkle/SparkleInstance.module.css"],"names":[],"mappings":"AAAA;EACE,4DAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,UAAU;EACV,0EAAmD;AACrD;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".sparkle {\n  animation: spin 600ms linear forwards;\n}\n\n.sparkle_wrapper {\n  position: absolute;\n  pointer-events: none;\n  z-index: 2;\n  animation: growAndShrink 600ms ease-in-out forwards;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(90deg);\n  }\n}\n\n@keyframes growAndShrink {\n  0% {\n    transform: scale(0);\n  }\n  50% {\n    transform: scale(1.5);\n  }\n  100% {\n    transform: scale(0);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sparkle": `SparkleInstance_sparkle__5a9KY`,
	"spin": `SparkleInstance_spin__bGKsh`,
	"sparkle_wrapper": `SparkleInstance_sparkle_wrapper__DO2Ur`,
	"growAndShrink": `SparkleInstance_growAndShrink__HnKYQ`
};
export default ___CSS_LOADER_EXPORT___;
